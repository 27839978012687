<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Packages Size
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Master</li>          
          <li class="breadcrumb-item active" aria-current="page">Packages Size</li>
        </ol>
      </nav>
    </div>
    <div class="row" ref="containerMain">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<b-modal
						:centered="true"
						:hide-footer="true"
						:title="titleModal"
						v-model="modalForm"
					>
						<b-row>
							<b-col class="mb-3" lg="12">
								<b-row>
									<b-col lg="6"><label>Name</label></b-col>
									<b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
								</b-row>
								<input type="text" v-model="form.name" class="form-control form-control-lg" placeholder="Name">
								<b-row class="mt-2">
									<b-col lg="6"><label>Delivery Type</label></b-col>
									<b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
								</b-row>
                <v-select v-model="optSelect2" :options="dataOption2"></v-select>                
								<b-row class="mt-2">
									<b-col lg="6"><label>Weight (default unit)</label></b-col>
									<b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
								</b-row>
                <v-select v-model="optSelect1" :options="dataOption1"></v-select>                
							</b-col> 
							<b-col class="mb-3 mb-xl-0" lg="12">
								<b-input-group>
									<b-button
										variant="primary"
										href="javascript:void(0)"
										@click="sendPayload()">Save
									</b-button>
								</b-input-group>
							</b-col>                
						</b-row>
					</b-modal>					
					<div class="card-body">
						<b-form-group
							label="Filter"
							label-for="filter-input"
							label-cols-md="3"
							label-align-md="right"
							label-size="md"
							class="mb-1"
						>
							<b-input-group size="md">
								<b-form-input
									id="filter-input"
									type="search"
									placeholder="Type to Search"
									style="margin-right: 0.5rem"
								></b-form-input>

								<b-input-group-append>
									<b-button
										style="margin-right: 0.5rem"
										>Search</b-button
									>
									<b-button >Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>							
					</div>
				</div>
      </div>

      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-head">
						<div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
							<button type="button"  
								class="btn btn-sm btn-primary btn-icon-text"
								@click="openAddModal()">
								Add
								<i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
							</button>							
						</div>
					</div>
					<div class="card-body">
						<b-table 
						responsive 
						:striped="tableConfig.striped"
						:bordered="tableConfig.bordered"
						:borderless="tableConfig.borderless"
						:outlined="tableConfig.outlined"
						:small="tableConfig.small"
						:hover="tableConfig.hover"
						:dark="tableConfig.dark"
						:fixed="tableConfig.fixed"
						:foot-clone="tableConfig.footClone"
						:no-border-collapse="tableConfig.noCollapse"
						:head-variant="tableConfig.headVariant"
						:table-variant="tableConfig.tableVariant"
						:items="data" 
						:fields="fields">
							<template #cell(index)="data">
								{{ data.index + 1 }}
							</template>						
							<template v-slot:cell(created_at)="row">
								{{ normalDateTime(row.item.created_at) }}
							</template>
							<template v-slot:cell(updated_at)="row">
								{{ normalDateTime(row.item.updated_at) }}
							</template>
							<template v-slot:cell(actions)="row">
								<button type="button"  
									class="btn btn-sm btn-success btn-icon-text"
									@click="openLink('weight-indicator',row.item)">
									Weight
									<i class="mdi mdi-file-check btn-icon-append"></i>
								</button>
								<br>
								<br>
                
								<button type="button"  
									class="btn btn-sm btn-success btn-icon-text"
									@click="openLink('dimensions-indicator',row.item)">
									Dimension
									<i class="mdi mdi-file-check btn-icon-append"></i>
								</button>
								<br>
								<br>
                
                
								<button type="button"  
									class="btn btn-sm btn-warning btn-icon-text"
									@click="openEditModal(row.item.uuid, row.item)">
									Edit
									<i class="mdi mdi-file-check btn-icon-append"></i>
								</button>
								<br>
								<br>

								<button type="button"  
									class="btn btn-sm btn-danger btn-icon-text"
									@click="openDeleteModal(row.item.uuid, row.item)">
									Delete
									<i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
								</button>
								<br>
							</template>							
						</b-table>
						<b-pagination
							v-model="page"
							:total-rows="totalRows"
							:per-page="limit"
							align="fill"
							size="md"
							class="mb-1"
							v-on:click.native="callback"
						></b-pagination>
            <b-row class="pt-4">
              <p class="col-lg-12 mb-1">Total Data : {{ data.length }}</p>                
              <p class="col-lg-12 mb-1">Total Data per Page : {{ totalRows }}</p>              
              <p class="col-lg-12 mb-1">Total Page : {{ lastPage }}</p>
            </b-row>            
					</div>
				</div>
      </div>			
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      data: [],
      dataOption1: [],
      optSelect1: '',
      dataOption2: [],
      optSelect2: '',      
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
				name: "",
      },						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'delivery_types_name', label: 'Delivery Type', class: "text-center", sortable: true},
				{key: 'units_of_measurements_name', label: 'Weight (Default)', class: "text-center", sortable: true},                
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
				{key: 'remarks', label: 'Remarks', class: "text-center", sortable: true},        
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    responseAxiosError: (error) => packageGlobals.responseAxiosError(error),    
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },
    openLink: function (routeLink, item) {
      this.$router.push(`/master/packages-size/${routeLink}/${item.uuid}`)      
    },
    getAll: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/package-size?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },
    getUnitMeasurementWeight: function () {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/units-of-measurement/byType/tuQpZRqQWzlvGnh`,packageGlobals.configAxios)
			.then((response) => {
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },
    getDeliveryType: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })
            
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/delivery-types?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption2.push(pushData)
        });
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openAddModal: function () {
      this.form.name = ""
      this.titleModal = "Add Packages Size"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Packages Size"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        })
        
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/master/package-size/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          });
        }
      })
    },		
    sendPayload: function () {
      let checkField = packageGlobals.checkField(
        [
          {value : this.form.name, message : 'field name is required', key:'name'}        
        ]
      )
      var flag = checkField.flag, message = checkField.message
      if (flag == 0) {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        })

        this.form.uuid_units_of_measurements = this.optSelect1['id']
        this.form.uuid_delivery_types = this.optSelect2['id']        
        this.form.uuid_features = '-'                
        if (this.stateCrud == "add") {
					this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/master/package-size`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/master/package-size`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          });
        } else if (this.stateCrud == "edit") {
					this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/master/package-size/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          });
        }
        // loader.hide()
      }
      else
      {
        this.$swal.fire({
          title: "Ups, There is a mistake",
          text: message,
          icon: "error",
          confirmButtonText: "Ok",
        });        
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)
		this.getUnitMeasurementWeight('-', 1, 999)
		this.getDeliveryType('-', 1, 999)        		
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>